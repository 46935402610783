import { connect } from 'react-redux';

import MenuQuery from 'Query/Menu.query';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import { updateMeta } from 'Store/Meta/Meta.action';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { setBigOfflineNotice } from 'Store/Offline/Offline.action';
import ContentfulEntryHelper from 'Util/ContentfulEntry';
import MenuHelper from 'Util/Menu';
import DataContainer from 'Util/Request/DataContainer';

import Footer from './Footer.component';

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);

/** @namespace LjrPwa/Component/Footer/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isOffline: state.OfflineReducer.isOffline,
    device: state.ConfigReducer.device,
    header_logo_src: state.ConfigReducer.header_logo_src
});

/** @namespace LjrPwa/Component/Footer/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateBreadcrumbs: (breadcrumbs) => BreadcrumbsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateWithCmsPage(breadcrumbs, dispatch)
    ),
    setHeaderState: (stateName) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName)),
    setBigOfflineNotice: (isBig) => dispatch(setBigOfflineNotice(isBig)),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    toggleBreadcrumbs: (isActive) => {
        BreadcrumbsDispatcher.then(
            ({ default: dispatcher }) => dispatcher.update([], dispatch)
        );
        dispatch(toggleBreadcrumbs(isActive));
    }
});

/** @namespace LjrPwa/Component/Footer/Container/FooterContainer */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
export class FooterContainer extends DataContainer {
    componentDidMount() {
        this._getMenu();
        this.fetchData(
            ContentfulEntryHelper.getQuery('entries.footer'),
            ({ contentfulEntry }) => {
                this.setState(ContentfulEntryHelper.reduce(contentfulEntry), this.populateHeaderStateFromStack);
            }
        );
    }

    _getMenuOptions() {
        const { header_content: { header_menu } = {} } = window.contentConfiguration;

        return {
            identifier: header_menu || 'new-main-menu'
        };
    }

    _getMenu() {
        this.fetchData(
            [MenuQuery.getQuery(this._getMenuOptions())],
            ({ menu }) => this.setState({
                menu: MenuHelper.reduce(menu)
            }, this.populateHeaderStateFromStack)
        );
    }

    render() {
        return (
            <Footer
              { ...this.props }
              { ...this.state }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterContainer);
