import {
    UPDATE_RECENTLY_VIEWED_PRODUCTS
} from 'SourceStore/RecentlyViewedProducts/RecentlyViewedProducts.action';

export {
    UPDATE_RECENTLY_VIEWED_PRODUCTS
};
export const ADD_RECENTLY_VIEWED_PRODUCT = 'ADD_RECENTLY_VIEWED_PRODUCT';

/** @namespace LjrPwa/Store/RecentlyViewedProducts/Action/addRecentlyViewedProduct */
export const addRecentlyViewedProduct = (product, store) => ({
    type: ADD_RECENTLY_VIEWED_PRODUCT,
    product,
    store
});

/**
 * Update RecentlyViewed products list.
 * @param  {Object} product Product returned from fetch
 * @return {void}
 * @namespace LjrPwa/Store/RecentlyViewedProducts/Action/updateRecentlyViewedProducts */
export const updateRecentlyViewedProducts = (products, storeCode) => ({
    type: UPDATE_RECENTLY_VIEWED_PRODUCTS,
    products,
    storeCode
});
