import {
    ConfigQuery as SourceConfigQuery
} from 'SourceQuery/Config.query';

/** @namespace LjrPwa/Query/Config/Query/ConfigQuery */
export class ConfigQuery extends SourceConfigQuery {
    _getStoreConfigFields() {
        const fields = super._getStoreConfigFields();
        fields.push('product_alert_allow_price');
        fields.push('product_alert_allow_stock');
        return fields;
    }
}

export default new ConfigQuery();
