import Event, {
    EVENT_GTM_USER_LOGIN,
    EVENT_GTM_USER_REGISTER
} from '../../util/Event';

// eslint-disable-next-line no-unused-vars
const createAccount = (args, callback, instance) => callback(...args)
        .then((signInPromise) => {
            Event.dispatch(EVENT_GTM_USER_REGISTER);

            return signInPromise;
        });

// eslint-disable-next-line no-unused-vars
const signIn = (args, callback, instance) => callback(...args)
        .then((result) => {
            Event.dispatch(EVENT_GTM_USER_LOGIN);

            return result;
        });

export default {
    'Store/MyAccount/Dispatcher': {
        'member-function': {
            createAccount,
            signIn
        }
    }
};
