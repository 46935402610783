import BrowserDatabase from 'Util/BrowserDatabase';
import { getIndexedProduct } from 'Util/Product';

import { UPDATE_SHIPPING_PRICE, UPDATE_TOTALS } from './Cart.action';

export const CART_TOTALS = 'cart_totals';

/** @namespace LjrPwa/Store/Cart/Reducer/updateCartTotals */
export const updateCartTotals = (action) => {
    const { cartData: cartTotals } = action;

    if (Object.hasOwnProperty.call(cartTotals, 'items')) {
        const normalizedItemsProduct = cartTotals.items.map((item) => {
            // eslint-disable-next-line no-unused-vars
            const { variants, ...normalizedItem } = item;

            const {
                image, // eslint-disable-line no-unused-vars
                small_image, // eslint-disable-line no-unused-vars
                product_links, // eslint-disable-line no-unused-vars
                variants: productVariants = [],
                ...reducedProduct
            } = getIndexedProduct(item.product, item.sku);

            reducedProduct.variants = productVariants.map((variant) => {
                const {
                    image, // eslint-disable-line no-unused-vars
                    small_image, // eslint-disable-line no-unused-vars
                    product_links, // eslint-disable-line no-unused-vars
                    ...reducedVariant
                } = variant;

                return reducedVariant;
            });

            normalizedItem.product = reducedProduct;

            return normalizedItem;
        });

        cartTotals.items = normalizedItemsProduct;
    }

    BrowserDatabase.setItem(
        cartTotals,
        CART_TOTALS
    );

    return { cartTotals };
};

/** @namespace LjrPwa/Store/Cart/Reducer/updateShippingPrice */
export const updateShippingPrice = (action, state) => {
    const {
        data: {
            items, // eslint-disable-line no-unused-vars
            ...rest
        } = {}
    } = action;

    return {
        cartTotals: {
            ...state.cartTotals,
            ...rest
        }
    };
};

/** @namespace LjrPwa/Store/Cart/Reducer/getInitialState */
export const getInitialState = () => ({
    cartTotals: BrowserDatabase.getItem(CART_TOTALS) || {}
});

/** @namespace LjrPwa/Store/Cart/Reducer/CartReducer */
export const CartReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
        case UPDATE_TOTALS:
            return updateCartTotals(action, state);
        case UPDATE_SHIPPING_PRICE:
            return updateShippingPrice(action, state);
        default:
            return state;
    }
};

export default CartReducer;
