import ContentfulEntryQuery from 'Query/ContentfulEntry.query';

/** @namespace LjrPwa/Util/ContentfulEntry/ContentfulEntry */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/derived-class-names
export class ContentfulEntry {
    reduce({ id, entry }) {
        const data = {};
        const categories = Object.keys(window.contentConfiguration.contentful);
        categories.forEach((category) => {
            const index = Object.values(window.contentConfiguration.contentful[category]).indexOf(id);
            if (index >= 0) {
                const name = Object.keys(window.contentConfiguration.contentful[category])[index];
                const prop = name.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
                data[prop] = JSON.parse(entry);
            }
        });

        return data;
    }

    getQuery(path) {
        const { contentful } = window.contentConfiguration;

        const [category, name] = path.split('.');

        if (typeof contentful[category] !== 'object') {
            throw new Error('Unable to find contentful configuration');
        }

        if (typeof contentful[category][name] !== 'string') {
            throw new Error('Unable to find contentful configuration');
        }

        return [ContentfulEntryQuery.getQuery({ id: contentful[category][name] })];
    }
}

export default new ContentfulEntry();
