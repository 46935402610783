import {
    FieldSelectContainer as SourceFieldSelectContainer
} from 'SourceComponent/FieldSelect/FieldSelect.container';

/** @namespace LjrPwa/Component/FieldSelect/Container/FieldSelectContainer */
export class FieldSelectContainer extends SourceFieldSelectContainer {
    sortSelectOptions() {
        const { id } = this.props;

        const sortedOptions = super.sortSelectOptions();

        if (id !== 'shoe_size') {
            return sortedOptions;
        }

        sortedOptions.sort((first, second) => {
            const firstIsPack = first.label.match(/([\d]+)([\s]+)?-([\s]+)?([\d]+)/);
            const secondIsPack = second.label.match(/([\d]+)([\s]+)?-([\s]+)?([\d]+)/);

            if (firstIsPack && secondIsPack) {
                const firstInt = parseInt(firstIsPack[1], 10);
                const secondInt = parseInt(secondIsPack[1], 10);
                return firstInt - secondInt;
            }

            if (firstIsPack) {
                return -1;
            }

            return secondIsPack ? 1 : (first.label - second.label);
        });

        return sortedOptions;
    }
}

export default FieldSelectContainer;
