import { UPDATE_CONFIG } from 'Store/Config/Config.action';
import BrowserDatabase from 'Util/BrowserDatabase';

import GoogleTagManager, { GROUPED_PRODUCTS_GUEST } from '../component/GoogleTagManager/GoogleTagManager.component';
import ProductHelper from '../component/GoogleTagManager/utils/Product';
import GtmQuery from '../query/Gtm.query';

// eslint-disable-next-line no-unused-vars
const handle_syncCartWithBEError = (args, callback, instance) => callback(...args)
        .then(
            (result) => {
                GoogleTagManager.getInstance().setGroupedProducts({});
                return result;
            }
        );

// eslint-disable-next-line no-unused-vars
const addGtmConfigQuery = (args, callback, instance) => {
    const original = callback(...args);
    return [
        ...(Array.isArray(original) ? original : [original]),
        GtmQuery.getGTMConfiguration()
    ];
};

// eslint-disable-next-line no-unused-vars
const addGtmToConfigReducerInitialState = (args, callback, instance) => {
    const { gtm } = BrowserDatabase.getItem('config') || { gtm: {} };

    return {
        ...callback(...args),
        gtm
    };
};

const addGtmToConfigUpdate = (args, callback, context) => {
    const [, action] = args;
    const originalUpdatedState = callback.apply(context, args);

    if (!action) {
        return originalUpdatedState;
    }

    const { config: { gtm } = {}, type } = action;

    if (type !== UPDATE_CONFIG) {
        return originalUpdatedState;
    }

    return {
        ...originalUpdatedState,
        gtm
    };
};

// eslint-disable-next-line no-unused-vars
const afterRequestCustomerData = (args, callback, instance) => {
    const gtm = GoogleTagManager.getInstance();

    /** transfer grouped products data from guest to logged in user */
    // eslint-disable-next-line no-unused-vars
    const transferGroupedProductsData = (id) => {
        if (gtm.groupedProductsStorageName !== GROUPED_PRODUCTS_GUEST) {
            return;
        }

        const guestGroupedProducts = gtm.getGroupedProducts();
        gtm.setGroupedProducts({});
        gtm.updateGroupedProductsStorageName(id);

        const userGroupedProducts = gtm.getGroupedProducts();
        const result = ProductHelper.mergeGroupedProducts(guestGroupedProducts, userGroupedProducts);

        gtm.setGroupedProducts(result);
    };

    // Graeme 03/01/2021 - removed this because `customer` is undefined and throwing errors
    // It's already been reported but not resolved - https://github.com/scandipwa/gtm-fe/issues/2

    // return callback(...args)
    //     .then((result) => {
    //         transferGroupedProductsData(customer.id); // eslint-disable-line no-undef
    //         gtm.updateGroupedProductsStorageName(customer.id); // eslint-disable-line no-undef
    //
    //         return result;
    //     });
};

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            handle_syncCartWithBEError
        }
    },
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: addGtmConfigQuery
        }
    },
    'Store/Config/Reducer/getInitialState': {
        function: addGtmToConfigReducerInitialState
    },
    'Store/Config/Reducer': {
        function: addGtmToConfigUpdate
    },
    'Store/MyAccount/Dispatcher': {
        'member-function': {
            requestCustomerData: afterRequestCustomerData
        }
    }
};
