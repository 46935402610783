/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable max-lines */
/* eslint-disable  react/jsx-no-bind */
import PropTypes from 'prop-types';

import ContentWrapper from 'Component/ContentWrapper';
import Link from 'Component/Link';
import Logo from 'Component/Logo';
import NewsletterSubscription from 'Component/NewsletterSubscription';
import { Footer as SourceFooter } from 'SourceComponent/Footer/Footer.component';
import { DeviceType } from 'Type/Device';
import { isSignedIn } from 'Util/Auth';
import CSS from 'Util/CSS';
import media from 'Util/Media';
import { LOGO_MEDIA } from 'Util/Media/Media';

/** @namespace LjrPwa/Component/Footer/Component/FooterComponent */
export class FooterComponent extends SourceFooter {
    static propTypes = {
        copyright: PropTypes.string,
        isVisibleOnMobile: PropTypes.bool,
        device: DeviceType.isRequired,
        header_logo_src: PropTypes.string,
        logo_alt: PropTypes.string,
        logo_height: PropTypes.number,
        logo_width: PropTypes.number
    };

    static defaultProps = {
        logo_alt: 'LJ&R logo',
        logo_height: 25,
        logo_width: 200,
        header_logo_src: '',
        isLoading: true,
        copyright: '',
        isVisibleOnMobile: false
    };

    renderColumnItemLink = ({ identifier = '', title, src }, i) => {
        const mods = src ? { type: 'image' } : {};

        return (
            <Link
              block="Footer"
              elem="ColumnItem"
              to={ '/'.concat(identifier) }
              mods={ mods }
              key={ i }
              aria-label={ title }
            >
                { this.renderColumnItemContent(src, title) }
            </Link>
        );
    };

    renderColumn = (column, i) => {
        const {
            title,
            pages,
            nonContentfulPage,
            isItemsHorizontal,
            mods = {}
        } = column;
        const contentMods = isItemsHorizontal ? { direction: 'horizontal' } : {};

        if (pages.length > 0) {
            return (
                <div block="Footer" elem="Column" mods={ mods } key={ i }>
                    <h3 block="Footer" elem="ColumnTitle">
                        { title }
                    </h3>
                    <div
                      block="Footer"
                      elem="ColumnContent"
                      mods={ contentMods }
                    >
                        { pages.map(this.renderColumnItem) }
                    </div>
                </div>
            );
        }

        if (nonContentfulPage.length > 0) {
            return (
                <div block="Footer" elem="Column" mods={ mods } key={ i }>
                    <h3 block="Footer" elem="ColumnTitle">
                        { title }
                    </h3>
                    <div
                      block="Footer"
                      elem="ColumnContent"
                      mods={ contentMods }
                    >
                        { nonContentfulPage.map(this.renderColumnItem) }
                    </div>
                </div>
            );
        }

        return null;
    };

    renderCompanyColumn = (title, loggedOutText, loggedInText) => (
            <div block="Footer" elem="Column">
                <h3 block="Footer" elem="ColumnTitle" className="CompanyTitle">
                    { title }
                </h3>
                <div
                  block="Footer"
                  elem="ColumnContent"
                  className="CompanyTitle"
                >
                    <p>
                        { isSignedIn() ? loggedInText : loggedOutText }
                    </p>
                </div>
                { isSignedIn()
                    ? this.renderSignedInAccountButton()
                    : this.renderAccountButton() }
            </div>
    );

    renderNewsletterSignup() {
        return (
          <div className="NewsletterWrapper">
              <h4>Sign up to our newsletter</h4>
            <NewsletterSubscription key="NewsletterSubscription" />
          </div>
        );
    }

    renderSignedInAccountButton() {
        return (
            <div className="Buttons">
                <button
                  block="Button"
                  mix={ { block: 'Footer', elem: 'ColumnContent' } }
                >
                    <Link
                      to="/my-account/dashboard"
                      style={ { color: 'white' } }
                      onClick={ () => window.scrollTo(0, 0) }
                    >
                        { __('View your account') }
                    </Link>
                </button>
            </div>
        );
    }

    renderColumns() {
        const { footer } = this.props;

        if (!footer) {
            return null;
        }

        return (
            <ContentWrapper
              isNotSection
              wrapperMix={ { block: 'Footer', elem: 'Columns' } }
              label=""
            >
                { this.renderCompanyColumn(footer.title, footer.footerText, footer.loggedInFooterText) }
                { footer.links.map(this.renderColumn) }
            </ContentWrapper>
        );
    }

    renderContent() {
        return (
            <div block="Footer" elem="Content">
                { this.renderColumns() }
            </div>
        );
    }

    renderLogo() {
        const {
            header_logo_src,
            logo_alt
        } = this.props;

        const logoSrc = header_logo_src ? media(header_logo_src, LOGO_MEDIA) : null;

        CSS.setVariable(this.logoRef, 'header-logo-height', '2rem');
        CSS.setVariable(this.logoRef, 'header-logo-width', '8rem');

        return (
            <Logo
              src={ logoSrc }
              alt={ logo_alt }
              title={ logo_alt }
            />
        );
    }

    renderCopyrightContent() {
        return (
            <ContentWrapper
              isNotSection
              mix={ { block: 'Footer', elem: 'CopyrightContentWrapper' } }
              wrapperMix={ { block: 'Footer', elem: 'CopyrightContent' } }
              label=""
            >
                <div block="Footer" elem="Copyright">
                    &copy;
                    { ' Copyright ' }
                    { new Date().getFullYear() }
                    { '. ' }
                    <strong>LJ & R Footwear Limited.</strong>
                    { ' All Rights Reserved |' }
                    { ' Developed by ' }
                    <a href="https://www.wedocode.co.uk">
                        We Do Code Ltd
                    </a>
                </div>
                <div className="copyrightLogo">
                    { this.renderLogo() }
                </div>
            </ContentWrapper>
        );
    }

    /* eslint-disable react/jsx-no-bind */
    renderAccountButton() {
        if (!isSignedIn()) {
            return (
                <div className="Buttons">
                    <button
                      block="Button"
                      mix={ { block: 'Footer', elem: 'ColumnContent' } }
                    >
                        <Link
                          to="/account/create"
                          style={ { color: 'white' } }
                          onClick={ () => (window.scrollTo(0, 0)) }
                        >
                            { __('Create an account') }
                        </Link>
                    </button>
                    <button
                      block="Button"
                      mix={ { block: 'Footer', elem: 'ColumnContent' } }
                      className="SignIn"
                    >
                        <Link
                          to="/account/login"
                          className="SignIn"
                          onClick={ () => window.scrollTo(0, 0) }
                        >
                            { __('Sign In') }
                        </Link>
                    </button>
                </div>
            );
        }

        return (
            <button
              block="Button"
              mix={ { block: 'Footer', elem: 'ColumnContent' } }
            >
                <Link
                  to="/account/dashboard"
                  style={ { color: 'white' } }
                >
                    { __('My Account') }
                </Link>
            </button>
        );
    }

    render() {
        const { isVisibleOnMobile, device } = this.props;

        if ((!isVisibleOnMobile && device.isMobile) || (isVisibleOnMobile && !device.isMobile)) {
            return null;
        }

        return (
            <>
                { this.renderNewsletterSignup() }
                <footer block="Footer" aria-label="Footer">
                    { this.renderContent() }
                    { this.renderCopyrightContent() }
                </footer>
            </>
        );
    }
}

export default FooterComponent;
