/* eslint-disable  @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import Menu from '@scandipwa/scandipwa/src/component/Menu/Menu.component';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    MenuContainer as SourceMenuContainer
} from 'SourceComponent/Menu/Menu.container';
import ContentfulEntryHelper from 'Util/ContentfulEntry';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace LjrPwa/Component/Menu/Container/MenuContainer */
export class MenuContainer extends SourceMenuContainer {
    componentDidMount() {
        const { device: { isMobile } } = this.props;

        [
            'entries.promo_banner',
            'entries.shop_column',
            'entries.bottom_bar',
            'entries.image_column'
        ].forEach((entry) => {
            this.fetchData(
                ContentfulEntryHelper.getQuery(entry),
                ({ contentfulEntry }) => {
                    this.setState(ContentfulEntryHelper.reduce(contentfulEntry), this.populateHeaderStateFromStack);
                }
            );
        });

        this._getMenu();

        if (isMobile) {
            window.addEventListener('popstate', this.historyBackHook);
        }
    }

    render() {
        return (
            <Menu
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);
