import {
    LINKED_PRODUCTS,
    LinkedProductsDispatcher as SourceLinkedProductsDispatcher
} from 'SourceStore/LinkedProducts/LinkedProducts.dispatcher';
import { updateLinkedProducts } from 'Store/LinkedProducts/LinkedProducts.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getIndexedProduct } from 'Util/Product';

export {
    LINKED_PRODUCTS
};

/** @namespace LjrPwa/Store/LinkedProducts/Dispatcher/LinkedProductsDispatcher */
export class LinkedProductsDispatcher extends SourceLinkedProductsDispatcher {
    clearLinkedProducts(dispatch, updateCrossSell = false) {
        const linkedProducts = {
            upsell: { total_count: 0, items: [] },
            related: { total_count: 0, items: [] },
            crosssell: { total_count: 0, items: [] },
            colour_bundle: { total_count: 0, items: [] }
        };

        BrowserDatabase.setItem(linkedProducts, LINKED_PRODUCTS);

        dispatch(updateLinkedProducts({
            ...linkedProducts,
            updateCrossSell
        }));
    }

    _processResponse(data, product_links) {
        const { products: { items } } = data;

        const indexedBySku = items.reduce((acc, item) => {
            const { sku } = item;
            acc[sku] = getIndexedProduct(item);
            return acc;
        }, {});

        const linkedProducts = product_links.reduce((acc, link) => {
            const { linked_product_sku, link_type } = link;

            if (indexedBySku[linked_product_sku] && acc[link_type]) {
                acc[link_type].items.push(
                    indexedBySku[linked_product_sku]
                );

                acc[link_type].total_count++;
            }

            return acc;
        }, {
            upsell: { total_count: 0, items: [] },
            related: { total_count: 0, items: [] },
            crosssell: { total_count: 0, items: [] },
            associated: { total_count: 0, items: [] },
            colour_bundle: { total_count: 0, items: [] }
        });

        return linkedProducts;
    }
}

export default new LinkedProductsDispatcher();
