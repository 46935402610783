import {
    Notification as SourceNotification
} from 'SourceComponent/Notification/Notification.component';

/** @namespace LjrPwa/Component/Notification/Component/NotificationComponent */
export class NotificationComponent extends SourceNotification {
    render() {
        const { notification } = this.props;
        const { isNotificationVisible } = this.state;
        const { msgText, msgType } = notification;

        const mods = {
            type: msgType.toLowerCase(),
            is: isNotificationVisible ? 'opening' : 'closing'
        };

        if (msgText === 'Internal server error') {
            return null;
        }

        return (
            <div block="Notification" mods={ mods } ref={ this.notification }>
                <button block="Notification" elem="Button" onClick={ this.hideNotification }>Close</button>
                <p block="Notification" elem="Text">{ msgText }</p>
                { this.renderDebug() }
            </div>
        );
    }
}

export default NotificationComponent;
