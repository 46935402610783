import {
    Breadcrumb as SourceBreadcrumb
} from 'SourceComponent/Breadcrumb/Breadcrumb.component';

import './Breadcrumb.style';

/** @namespace LjrPwa/Component/Breadcrumb/Component/BreadcrumbComponent */
export class BreadcrumbComponent extends SourceBreadcrumb {
    // TODO implement logic
}

export default BreadcrumbComponent;
