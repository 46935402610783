import Carousel, { autoplayPlugin } from '@brainhubeu/react-carousel';
import * as icons from '@iconscout/react-unicons';
import { DeviceType } from '@scandipwa/scandipwa/src/type/Device';
import React, { Component } from 'react';

import { ContentfulDataType } from 'Type/ContentfulData';
import { transformIcon } from 'Util/Transform/TransformIcon';

import './PromoBanner.style.scss';
import '@brainhubeu/react-carousel/lib/style.css';

/** @namespace LjrPwa/Component/PromoBanner/Component/PromoBannerComponent */
export class PromoBannerComponent extends Component {
    static propTypes = {
        promoBanner: ContentfulDataType.isRequired,
        device: DeviceType.isRequired
    };

    renderContent(Icon, item) {
        if (!item) {
            return null;
        }

        if (Icon === null) {
            return (
                <p>{ item.text }</p>
            );
        }

        return (
            <p>
                <Icon className="promoIcon" />
                { item.text }
            </p>
        );
    }

    renderMobilePromo() {
        const { promoBanner } = this.props;
        if (!promoBanner) {
            return null;
        }

        return (
            <div className="promoBar">
                <div className="promo">
                    <Carousel
                      plugins={ [
                          'infinite',
                          {
                              resolve: autoplayPlugin,
                              options: {
                                  interval: 4000
                              }
                          }
                      ] }
                      animationSpeed={ 1000 }
                    >
                        { promoBanner.items
                        && promoBanner.items.map((item) => {
                            const icon = item.iconName;
                            const Icon = icon ? icons[transformIcon(icon)] : null;
                            return (
                                <p>
                                    <Icon className="promoIcon" />
                                    { item.text }
                                </p>
                            );
                        }) }
                    </Carousel>
                </div>
            </div>
        );
    }

    render() {
        const { promoBanner } = this.props;
        const { device } = this.props;

        if (!promoBanner) {
            return null;
        }

        if (!promoBanner.items) {
            return null;
        }

        if (device.isMobile && promoBanner.items.length > 1) {
            return this.renderMobilePromo();
        }

        return (
            <div className="promoBar">
                <div className="promo">
                    { promoBanner.items
                    && promoBanner.items.map((item) => {
                        const icon = item.iconName;
                        const Icon = icon ? icons[transformIcon(icon)] : null;
                        return this.renderContent(Icon, item);
                    }) }
                </div>
            </div>
        );
    }
}

export default PromoBannerComponent;
