import { UPDATE_LINKED_PRODUCTS } from '@scandipwa/scandipwa/src/store/LinkedProducts/LinkedProducts.action';
import { LINKED_PRODUCTS } from '@scandipwa/scandipwa/src/store/LinkedProducts/LinkedProducts.dispatcher';

import {
    CROSS_SELL,
    RELATED,
    UPSELL
} from 'SourceStore/LinkedProducts/LinkedProducts.reducer';
import BrowserDatabase from 'Util/BrowserDatabase';

export {
    UPSELL,
    RELATED,
    CROSS_SELL
};

export const COLOUR_BUNDLE = 'colour_bundle';

/** @namespace LjrPwa/Store/LinkedProducts/Reducer/getInitialState */
export const getInitialState = () => ({
    linkedProducts: BrowserDatabase.getItem(LINKED_PRODUCTS) || {
        upsell: {},
        related: {},
        crosssell: {},
        colour_bundle: {}
    }
});

/** @namespace LjrPwa/Store/LinkedProducts/Reducer/LinkedProductsReducer */
export const LinkedProductsReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    if (type !== UPDATE_LINKED_PRODUCTS) {
        return state;
    }

    const {
        linkedProducts: {
            [UPSELL]: upsell,
            [RELATED]: related,
            [CROSS_SELL]: crosssell,
            [COLOUR_BUNDLE]: colour_bundle,
            updateCrossSell = false
        }
    } = action;

    const {
        linkedProducts: {
            [CROSS_SELL]: prevCrossSell
        }
    } = state;

    if (updateCrossSell) {
        return {
            ...state,
            linkedProducts: {
                [UPSELL]: upsell,
                [RELATED]: related,
                [CROSS_SELL]: crosssell,
                [COLOUR_BUNDLE]: colour_bundle
            }
        };
    }

    return {
        ...state,
        linkedProducts: {
            [UPSELL]: upsell,
            [RELATED]: related,
            [CROSS_SELL]: {
                ...prevCrossSell,
                ...related,
                items: Object.values({
                    ...prevCrossSell.items,
                    ...crosssell.items
                })
            },
            [COLOUR_BUNDLE]: colour_bundle
        }
    };
};

export default LinkedProductsReducer;
