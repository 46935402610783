import { Field } from 'Util/Query';

/**
 * Contentful Entry Query
 * @class ContentfulEntryQuery
 * @namespace LjrPwa/Query/ContentfulEntry/Query/ContentfulEntryQuery
 */
export class ContentfulEntryQuery {
    /**
     * get Contentful Entryy query
     * @param  {{id: String, entry: String}} options A object containing different aspects of query, each item can be omitted
     * @return {Query} Contentful Entry query
     * @memberof ContentfulEntryQuery
     */
    getQuery({ id }) {
        if (!id) {
            throw new Error('Missing argument `id`!');
        }

        return new Field('contentfulEntry')
            .addFieldList(this._getFields())
            .addArgument('id', 'String!', id);
    }

    _getFields() {
        return [
            'id',
            'entry'
        ];
    }
}

export default new ContentfulEntryQuery();
