import { BEFORE_ITEMS_TYPE } from 'Component/Router/Router.config';

import GoogleTagManager from '../component/GoogleTagManager';

/* eslint-disable-next-line no-unused-vars */
const addGtmComponent = (member, context) => {
    const maxPosition = Math.max(
        member.map((route) => route.position).filter((num) => num <= 1000) // eslint-disable-line no-magic-numbers
    );

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    return [
        ...member,
        {
            component: <GoogleTagManager />,
            position: maxPosition + 10 // eslint-disable-line no-magic-numbers
        }
    ];
};

export default {
    'Component/Router/Component': {
        'member-property': {
            [BEFORE_ITEMS_TYPE]: addGtmComponent
        }
    }
};
