import { UilAngleRight } from '@iconscout/react-unicons';

import Link from 'Component/Link';
import {
    MenuItem as SourceMenuItem
} from 'SourceComponent/MenuItem/MenuItem.component';

/** @namespace LjrPwa/Component/MenuItem/Component/MenuItemComponent */
export class MenuItemComponent extends SourceMenuItem {
    renderItemContentTitle(isBanner, title) {
        const { device } = this.props;
        if (isBanner) {
            return (
                <button
                  block="Menu"
                  elem="Button"
                  mix={ { block: 'Button' } }
                >
                    { title }
                </button>
            );
        }

        return (
            <span>
                { device.isMobile && 'Shop' }
                { ' ' }
                { title }
            </span>
        );
    }

    renderItemContent(item, itemMods = {}) {
        const { title, icon } = item;
        const { isBanner } = itemMods;
        const { device } = this.props;

        return (
            <figure
              block="Menu"
              elem="ItemFigure"
              mods={ itemMods }
            >
                { this.renderItemContentImage(icon, itemMods) }
                <h4
                  block="Menu"
                  elem="ItemCaption"
                  mods={ itemMods }
                >
                    { this.renderItemContentTitle(isBanner, title) }
                    { device.isMobile && <UilAngleRight /> }
                </h4>
            </figure>
        );
    }

    renderItemLinkContent() {
        const {
            activeMenuItemsStack,
            item,
            itemMods,
            handleCategoryHover,
            handleLinkLeave,
            onItemClick
        } = this.props;

        const {
            url,
            item_id,
            item_class
        } = item;

        const isHovered = activeMenuItemsStack.includes(item_id);

        const elems = [];

        elems.push('Link');
        if (item_class) {
            elems.push(item_class);
        }

        const elem = elems.join(' ');

        return (
            <Link
              to={ url }
              block="Menu"
              elem={ elem }
              id={ item_id }
              onMouseEnter={ handleCategoryHover }
              onMouseLeave={ handleLinkLeave }
              mods={ { isHovered } }
              onClick={ onItemClick }
            >
                { this.renderItemContent(item, itemMods) }
            </Link>
        );
    }
}

export default MenuItemComponent;
