import {
    MyAccountQuery as SourceMyAccountQuery
} from 'SourceQuery/MyAccount.query';
import { Field } from 'Util/Query';

/** @namespace LjrPwa/Query/MyAccount/Query/MyAccountQuery */
export class MyAccountQuery extends SourceMyAccountQuery {
    getResetPasswordMutation(options) {
        const {
            customer_id,
            token,
            password,
            password_confirmation
        } = options;

        return new Field('s_resetPassword')
            .addArgument('token', 'String!', token)
            .addArgument('password', 'String!', password)
            .addArgument('password_confirmation', 'String!', password_confirmation)
            .addArgument('customer_id', 'String!', customer_id)
            .addField('status');
    }

    getCreateAccountMutation(options) {
        const mutation = super.getCreateAccountMutation(options);
        mutation.addField(this.getApprovalField());
        mutation.addField(this.getWhereDoYouSellField());
        return mutation;
    }

    getApprovalField() {
        return new Field('approval')
            .addFieldList(this.getApprovalFields());
    }

    getApprovalFields() {
        return [
            'status',
            'message',
            'allow_login'
        ];
    }

    getWhereDoYouSellField() {
        return new Field('where_do_you_sell');
    }
}

export default new MyAccountQuery();
