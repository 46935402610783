/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import {
    Form as SourceForm
} from 'SourceComponent/Form/Form.component';

import validationConfig from './Form.config';

/** @namespace LjrPwa/Component/Form/Component/FormComponent */
export class FormComponent extends SourceForm {
    static validateField(field, refMap) {
        const { validation, id, name } = field.props;

        if (validation && id && refMap[name] && refMap[name].current) {
            const { current: inputNode } = refMap[name];

            const rule = validation.find((rule) => {
                if (!validationConfig[rule]) {
                    return false;
                }
                const validationRules = validationConfig[rule];
                const isValid = validationRules.validate(inputNode, refMap);
                return !isValid;
            });

            if (rule) {
                return validationConfig[rule];
            }
        }

        return {};
    }
}

export default FormComponent;
