import {
    UPDATE_INFO_LOAD_STATUS,
    UPDATE_PRODUCT_LIST_INFO,
    updateInfoLoadStatus,
    updateProductListInfo as sourceUpdateProductListInfo
} from 'SourceStore/ProductListInfo/ProductListInfo.action';

export {
    UPDATE_PRODUCT_LIST_INFO,
    UPDATE_INFO_LOAD_STATUS,
    updateInfoLoadStatus
};

// TODO: implement updateProductListInfo
export const updateProductListInfo = sourceUpdateProductListInfo;
