import { NONE_SORT_OPTION_VALUE } from 'Route/SearchPage/SearchPage.config';
import {
    ProductListQuery as SourceProductListQuery
} from 'SourceQuery/ProductList.query';
import { Field } from 'Util/Query';

/** @namespace LjrPwa/Query/ProductList/Query/ProductListQuery */
export class ProductListQuery extends SourceProductListQuery {
    _getFilterArgumentMap() {
        const map = super._getFilterArgumentMap();

        return {
            ...map,
            newToDate: (date) => ({ news_to_date: { from: date } })
        };
    }

    _getItemsField() {
        const items = super._getItemsField();
        items.addField(this._getBrandField());
        items.addField(this._getPreOrderField());
        items.addField(this._getLoosePriceField());
        return items;
    }

    _getProductInterfaceFields(isVariant, isForLinkedProducts = false) {
        const fields = super._getProductInterfaceFields(isVariant, isForLinkedProducts);
        fields.push(this._getPreOrderField());
        return fields;
    }

    _getBrandField() {
        return new Field('brand')
            .addFieldList(this._getBrandFields());
    }

    _getBrandFields() {
        return [
            'brand_id',
            'title',
            'description',
            'url_key',
            'image',
            'position',
            'status'
        ];
    }

    _getPreOrderField() {
        return new Field('mp_pre_order')
            .addFieldList(this._getPreOrderFields());
    }

    _getPreOrderFields() {
        return [
            'stock_notice',
            'button_label',
            'children',
            'option_map'
        ];
    }

    _getLoosePriceField() {
        return new Field('loose_price');
    }

    _getAggregationsFields() {
        return [
            new Field('label').setAlias('name'),
            new Field('attribute_code').setAlias('request_var'),
            'is_boolean',
            this._getAggregationsOptionsField()
        ];
    }

    _getArgumentsMap() {
        const map = super._getArgumentsMap();

        map.sort.handler = ({ sortKey, sortDirection }) => {
            if (sortKey === NONE_SORT_OPTION_VALUE) {
                return {};
            }

            return { [sortKey]: sortDirection || 'ASC' };
        };

        return map;
    }
}

export default new ProductListQuery();
