/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
import { BEFORE_ITEMS_TYPE } from '@scandipwa/scandipwa/src/component/Router/Router.config';

import Breadcrumbs from 'Component/Breadcrumbs';
import Header from 'Component/Header';
import NewVersionPopup from 'Component/NewVersionPopup';
import NotificationList from 'Component/NotificationList';
import { Router as SourceRouter } from 'SourceComponent/Router/Router.component';

/** @namespace LjrPwa/Component/Router/Component/RouterComponent */
export class RouterComponent extends SourceRouter {
    [BEFORE_ITEMS_TYPE] = [
        {
            component: <NotificationList />,
            position: 10
        },
        {
            component: <Header />,
            position: 20
        },
        {
            component: <Breadcrumbs />,
            position: 30
        },
        {
            component: <NewVersionPopup />,
            position: 35
        }
    ];
}

export default RouterComponent;
